<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="tenderModel"
    label-width="140px"
    >
    <el-divider>
      <h6>基本信息</h6>
    </el-divider>
    <div class="form-wrapper">
      <el-form-item label="名称：" prop="tenderName">
        <el-input
          v-model="tenderModel.tenderName"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="全名称：" prop="tenderFullName">
        <el-input
          v-model="tenderModel.tenderFullName"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="工程类型：" prop="engineeringType">
        <el-select v-model="tenderModel.engineeringType" clearable>
          <el-option
            v-for="engineeringType in engineeringTypes"
            :key="engineeringType.dictionaryGuid"
            :label="engineeringType.dictionaryValue"
            :value="engineeringType.dictionaryValue"
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="合同类型：" prop="contractType">
        <el-select v-model="tenderModel.contractType" clearable>
          <el-option
            v-for="contractType in contractTypes"
            :key="contractType.dictionaryGuid"
            :label="contractType.dictionaryValue"
            :value="contractType.dictionaryValue"
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="长度：" prop="tenderLength">
        <el-input
          v-model="tenderModel.tenderLength"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="起始桩号：" prop="startMileage">
        <el-input
          v-model="tenderModel.startMileage"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="终止桩号：" prop="endMileage">
        <el-input
          v-model="tenderModel.endMileage"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="开工日期：" prop="startDate">
        <el-date-picker
          v-model="tenderModel.startDate"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          style="width: 100%;">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="竣工日期：" prop="completeDate">
        <el-date-picker
          v-model="tenderModel.completeDate"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          style="width: 100%;">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="最后上报日：" prop="deadLine">
        <el-date-picker
          v-model="tenderModel.deadLine"
          type="date"
          format="dd"
          value-format="dd"
          style="width: 100%;">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="中标金额：" prop="winningAmount">
        <el-input-number
          v-model.number="tenderModel.winningAmount"
          :step="1"
          step-strictly
          :min="0"
          >
        </el-input-number>
      </el-form-item>
      <el-form-item label="合同金额：" prop="contractAmount">
        <el-input-number
          v-model.number="tenderModel.contractAmount"
          :step="1"
          step-strictly
          :min="0"
          >
        </el-input-number>
      </el-form-item>
      <el-form-item label="清单金额：" prop="listAmount">
        <el-input-number
          v-model.number="tenderModel.listAmount"
          :step="1"
          step-strictly
          :min="0"
          >
        </el-input-number>
      </el-form-item>
      <el-form-item label="施工单位：" prop="constructionUnit">
        <el-input
          v-model="tenderModel.constructionUnit"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="监理单位：" prop="superviseUnit">
        <el-input
          v-model="tenderModel.superviseUnit"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="监理单位代号：" prop="superviseCode">
        <el-input
          v-model="tenderModel.superviseCode"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="试验检测单位：" prop="testUnit">
        <el-input
          v-model="tenderModel.testUnit"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="试验检测单位代号：" prop="testCode">
        <el-input
          v-model="tenderModel.testCode"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="业主单位：" prop="ownerUnit">
        <el-input
          v-model="tenderModel.ownerUnit"
          type="text">
        </el-input>
      </el-form-item>
      <template v-if="!tenderModel.resourceId">
        <el-form-item label="所属公司：">
          <el-select v-model="companyGuid" clearable>
            <el-option
              v-for="company in companys"
              :key="company.companyGuid"
              :label="company.companyName"
              :value="company.companyGuid"
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属项目：" prop="projectGuid">
          <el-select v-model="tenderModel.projectGuid" clearable>
            <el-option
              v-for="project in projects"
              :key="project.projectGuid"
              :label="project.projectName"
              :value="project.projectGuid"
              >
            </el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item label="所属项目：">
          <el-input
            disabled
            :value="tenderModel.projectName">
          </el-input>
        </el-form-item>
      </template>
      <el-form-item label="排序：" prop="sortId">
        <el-input-number
          v-model="tenderModel.sortId"
          :step="1"
          :min="0"
          :precision="0"
          step-strictly>
        </el-input-number>
      </el-form-item>
      <el-form-item label="姓名1：" prop="name1">
        <el-input
          v-model="tenderModel.name1"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="姓名2：" prop="name2">
        <el-input
          v-model="tenderModel.name2"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="姓名3：" prop="name3">
        <el-input
          v-model="tenderModel.name3"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="姓名4：" prop="name4">
        <el-input
          v-model="tenderModel.name4"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="姓名5：" prop="name5">
        <el-input
          v-model="tenderModel.name5"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="姓名6：" prop="name6">
        <el-input
          v-model="tenderModel.name6"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="姓名7：" prop="name7">
        <el-input
          v-model="tenderModel.name7"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="姓名8：" prop="name8">
        <el-input
          v-model="tenderModel.name8"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="姓名9：" prop="name9">
        <el-input
          v-model="tenderModel.name9"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="姓名10：" prop="name10">
        <el-input
          v-model="tenderModel.name10"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="姓名11：" prop="name11">
        <el-input
          v-model="tenderModel.name11"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="姓名12：" prop="name12">
        <el-input
          v-model="tenderModel.name12"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="姓名13：" prop="name13">
        <el-input
          v-model="tenderModel.name13"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="姓名14：" prop="name14">
        <el-input
          v-model="tenderModel.name14"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="姓名15：" prop="name15">
        <el-input
          v-model="tenderModel.name15"
          type="text">
        </el-input>
      </el-form-item>
      <el-form-item label="是否合同段" prop="tenderType">
        <el-switch
          v-model="tenderModel.tenderType"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="0"
          active-text="是"
          inactive-text="否">
        </el-switch>
      </el-form-item>
    </div>
    <el-divider>
      <h6>开工预付款</h6>
    </el-divider>
    <div class="form-wrapper">
      <el-form-item label="起扣比例：" prop="rebateCommenceStartPercentage">
        <el-input-number
          v-model.number="tenderModel.rebateCommenceStartPercentage"
          :step="0.01"
          :precision="3"
          :min="0"
          :max="1"
          >
        </el-input-number>
      </el-form-item>
      <el-form-item label="止扣比例：" prop="rebateCommenceEndPercentage">
        <el-input-number
          v-model.number="tenderModel.rebateCommenceEndPercentage"
          :step="0.01"
          :precision="3"
          :min="0"
          :max="1"
          >
        </el-input-number>
      </el-form-item>
    </div>
    <el-divider>
      <h6>保留金</h6>
    </el-divider>
    <div class="form-wrapper">
      <el-form-item label="回扣比例：" prop="rebateRetentionPercentage">
        <el-input-number
          v-model.number="tenderModel.rebateRetentionPercentage"
          :step="0.01"
          :precision="3"
          :min="0"
          :max="1"
          >
        </el-input-number>
      </el-form-item>
      <el-form-item label="回扣上限：" prop="rebateRetentionLimitPercentage">
        <el-input-number
          v-model.number="tenderModel.rebateRetentionLimitPercentage"
          :step="0.01"
          :precision="3"
          :min="0"
          :max="1"
          >
        </el-input-number>
      </el-form-item>
      <el-form-item label="回扣基准：" prop="rebateRetentionBaseline">
        <el-switch
          v-model="tenderModel.rebateRetentionBaseline"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="list"
          inactive-value="contract"
          active-text="按清单内"
          inactive-text="按合同金额">
        </el-switch>
      </el-form-item>
    </div>
    <el-divider>
      <h6>暂定金</h6>
    </el-divider>
    <div class="form-wrapper">
      <el-form-item label="暂定金1：" prop="tempAmount1">
        <el-input-number
          v-model.number="tenderModel.tempAmount1"
          :step="1"
          step-strictly
          :min="0"
          >
        </el-input-number>
      </el-form-item>
      <el-form-item label="暂定金2：" prop="tempAmount2">
        <el-input-number
          v-model.number="tenderModel.tempAmount2"
          :step="1"
          step-strictly
          :min="0"
          >
        </el-input-number>
      </el-form-item>
      <el-form-item label="暂定金3：" prop="tempAmount3">
        <el-input-number
          v-model.number="tenderModel.tempAmount3"
          :step="1"
          step-strictly
          :min="0"
          >
        </el-input-number>
      </el-form-item>
    </div>
    <el-divider>
      <h6>报表配置</h6>
    </el-divider>
    <div class="form-wrapper">
      <el-form-item label="报表模板：" prop="reportTemplateGuid">
        <el-select v-model="tenderModel.reportTemplateGuid" clearable>
          <el-option
            v-for="reportTemplate in reportTemplates"
            :key="reportTemplate.reportTemplateGuid"
            :label="reportTemplate.reportTemplateName"
            :value="reportTemplate.reportTemplateGuid"
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="A15数量为0：" prop="a15ZeroDisplay">
        <el-switch
          v-model="tenderModel.a15ZeroDisplay"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="Y"
          inactive-value="N"
          active-text="显示"
          inactive-text="隐藏">
        </el-switch>
      </el-form-item>
      <el-form-item label="F03数量为0：">
        <el-switch
          v-model="tenderModel.f03ZeroDisplay"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="Y"
          inactive-value="N"
          active-text="显示"
          inactive-text="隐藏">
        </el-switch>
      </el-form-item>
      <el-form-item label="百章小计：">
        <el-switch
          v-model="tenderModel.chapterSumDisplay"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="Y"
          inactive-value="N"
          active-text="显示"
          inactive-text="隐藏">
        </el-switch>
      </el-form-item>
      <el-form-item label="计量方式：" prop="meterageType">
        <el-select v-model="tenderModel.meterageType" clearable>
          <el-option
            v-for="meterageType in meterageTypes"
            :key="meterageType.dictionaryGuid"
            :label="meterageType.dictionaryValue"
            :value="meterageType.dictionaryValue"
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="A15流水编号：">
        <el-switch
          v-model.number="tenderModel.a15SerialNo"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="0"
          active-text="自定义"
          inactive-text="自动生成">
        </el-switch>
      </el-form-item>
      <el-form-item label="A15自动日期：">
        <el-switch
          v-model.number="tenderModel.a15AutoDate"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="0"
          active-text="自定义"
          inactive-text="自动生成">
        </el-switch>
      </el-form-item>
      <el-form-item label="编码规则：">
        <el-input type="textarea" v-model="tenderModel.reportCode">
        </el-input>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import TenderModel from '@/model/TenderModel'
import reportTemplateService from '@/services/reportTemplateService'
import dictionaryService from '@/services/dictionaryService'
import PageInfo from '@/support/PageInfo'
import config from '@/config'
import GetProject from '@/mixins/GetProject'
import { lessThenZero } from '@/common/validator'

export default {
  name: 'TenderForm',
  mixins: [
    GetProject
  ],
  props: {
    tenderModel: {
      type: TenderModel,
      default () {
        return new TenderModel()
      }
    }
  },
  watch: {
    companyGuid (newVal) {
      this.tenderModel.projectGuid = ''
      this.projects = []
      newVal && this._getProjects(newVal)
    }
  },
  data () {
    return {
      companyGuid: '',
      reportTemplates: [],
      engineeringTypes: [],
      contractTypes: [],
      meterageTypes: [],
      rules: {
        tenderName: [
          {
            required: true,
            message: '请输入标段名称',
            trigger: 'blur'
          }
        ],
        engineeringType: [
          {
            required: true,
            message: '请输入工程类型',
            trigger: 'blur'
          }
        ],
        startMileage: [
          {
            required: true,
            message: '请输入起始桩号',
            trigger: 'blur'
          }
        ],
        endMileage: [
          {
            required: true,
            message: '请输入终止桩号',
            trigger: 'blur'
          }
        ],
        tenderLength: [
          {
            required: true,
            message: '请输入长度',
            trigger: 'blur'
          }
        ],
        startDate: [
          {
            required: true,
            message: '请输入开工日期',
            trigger: 'blur'
          }
        ],
        completeDate: [
          {
            required: true,
            message: '请输入竣工日期',
            trigger: 'blur'
          }
        ],
        projectGuid: [
          {
            required: true,
            message: '请选择所属项目',
            trigger: 'blur'
          }
        ],
        constructionUnit: [
          {
            required: true,
            message: '请输入施工单位',
            trigger: 'blur'
          }
        ],
        superviseUnit: [
          {
            required: true,
            message: '请输入监理单位',
            trigger: 'blur'
          }
        ],
        superviseCode: [
          {
            required: true,
            message: '请输入监理单位代号',
            trigger: 'blur'
          }
        ],
        ownerUnit: [
          {
            required: true,
            message: '请输入业主单位',
            trigger: 'blur'
          }
        ],
        reportTemplateGuid: [
          {
            required: true,
            message: '请选择报表模板',
            trigger: 'blur'
          }
        ],
        winningAmount: [
          {
            validator: lessThenZero,
            trigger: 'blur'
          }
        ],
        contractAmount: [
          {
            validator: lessThenZero,
            trigger: 'blur'
          }
        ],
        listAmount: [
          {
            validator: lessThenZero,
            trigger: 'blur'
          }
        ],
        rebateCommenceStartPercentage: [
          {
            validator: lessThenZero,
            trigger: 'blur'
          }
        ],
        rebateCommenceEndPercentage: [
          {
            validator: lessThenZero,
            trigger: 'blur'
          }
        ],
        meterageType: [
          {
            required: true,
            message: '请选择计量方式',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    },
    _getReportTemplates () {
      reportTemplateService.list()
        .then(res => {
          if (res.data.code === 1) {
            this.reportTemplates = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          window.concole.log(err.request.response)
        })
    },
    _getEngineeringTypes () {
      dictionaryService.list(new PageInfo({ pageSize: config.bigPageSize, pageIndex: 1, fieldOrder: 'sortId' }), { dictionaryKey: 'tenderEngineeringType' })
        .then(res => {
          if (res.data.code === 1) {
            this.engineeringTypes = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    },
    _getContractTypes () {
      dictionaryService.list(new PageInfo({ pageSize: config.bigPageSize, pageIndex: 1, fieldOrder: 'sortId' }), { dictionaryKey: 'tenderContractType' })
        .then(res => {
          if (res.data.code === 1) {
            this.contractTypes = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    },
    _getMeterageTypes () {
      dictionaryService.list(new PageInfo({ pageSize: config.bigPageSize, pageIndex: 1, fieldOrder: 'sortId' }), { dictionaryKey: 'tenderMeterageType' })
        .then(res => {
          if (res.data.code === 1) {
            this.meterageTypes = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    }
  },
  created () {
    this._getReportTemplates()
    this._getEngineeringTypes()
    this._getContractTypes()
    this._getMeterageTypes()
  }
}
</script>

<style lang="scss">
.form-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}
</style>
