import utility from '@/common/utility'
import auth from '@/common/auth'

export default class TenderModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.tenderGuid
      this.tenderGuid = props.tenderGuid
      this.tenderName = props.tenderName
      this.engineeringType = props.engineeringType
      this.contractType = props.contractType
      this.startMileage = props.startMileage
      this.endMileage = props.endMileage
      this.tenderLength = props.tenderLength
      this.startDate = props.startDate
      this.completeDate = props.completeDate
      this.deadLine = props.deadLine
      this.winningAmount = props.winningAmount
      this.contractAmount = props.contractAmount
      this.listAmount = props.listAmount
      this.rebateCommenceStartPercentage = props.rebateCommenceStartPercentage
      this.rebateCommenceEndPercentage = props.rebateCommenceEndPercentage
      this.rebateRetentionPercentage = props.rebateRetentionPercentage
      this.rebateRetentionLimitPercentage = props.rebateRetentionLimitPercentage
      this.rebateRetentionBaseline = props.rebateRetentionBaseline
      this.reportTemplateGuid = props.reportTemplateGuid
      this.reportTemplateName = props.reportTemplateName
      this.a15ZeroDisplay = props.a15ZeroDisplay
      this.f03ZeroDisplay = props.f03ZeroDisplay
      this.chapterSumDisplay = props.chapterSumDisplay
      this.tempAmount1 = props.tempAmount1
      this.tempAmount2 = props.tempAmount2
      this.tempAmount3 = props.tempAmount3
      this.projectGuid = props.projectGuid
      this.projectName = props.projectName
      this.constructionUnit = props.constructionUnit
      this.superviseUnit = props.superviseUnit
      this.testUnit = props.testUnit
      this.ownerUnit = props.ownerUnit
      this.superviseCode = props.superviseCode
      this.testCode = props.testCode
      this.staffName = props.staffName
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
      this.meterageType = props.meterageType
      this.a15SerialNo = props.a15SerialNo
      this.a15AutoDate = props.a15AutoDate
      this.name1 = props.name1
      this.name2 = props.name2
      this.name3 = props.name3
      this.name4 = props.name4
      this.name5 = props.name5
      this.name6 = props.name6
      this.name7 = props.name7
      this.name8 = props.name8
      this.name9 = props.name9
      this.name10 = props.name10
      this.name11 = props.name11
      this.name12 = props.name12
      this.name13 = props.name13
      this.name14 = props.name14
      this.name15 = props.name15
      this.reportCode = props.reportCode
      this.tenderType = props.tenderType
      this.tenderFullName = props.tenderFullName
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.tenderName) this.tenderName = ''
    if (!this.engineeringType) this.engineeringType = ''
    if (!this.contractType) this.contractType = ''
    if (!this.startMileage) this.startMileage = ''
    if (!this.endMileage) this.endMileage = ''
    if (!this.tenderLength) this.tenderLength = ''
    if (!this.startDate) this.startDate = ''
    if (!this.completeDate) this.completeDate = ''
    if (!this.deadLine) this.deadLine = ''
    if (!this.winningAmount) this.winningAmount = 0
    if (!this.contractAmount) this.contractAmount = 0
    if (!this.listAmount) this.listAmount = 0
    if (!this.rebateCommenceStartPercentage) this.rebateCommenceStartPercentage = 0
    if (!this.rebateCommenceEndPercentage) this.rebateCommenceEndPercentage = 0
    if (!this.rebateRetentionPercentage) this.rebateRetentionPercentage = 0
    if (!this.rebateRetentionBaseline) this.rebateRetentionBaseline = ''
    if (!this.reportTemplateName) this.reportTemplateName = ''
    if (!this.a15ZeroDisplay) this.a15ZeroDisplay = ''
    if (!this.f03ZeroDisplay) this.f03ZeroDisplay = ''
    if (!this.chapterSumDisplay) this.chapterSumDisplay = ''
    if (!this.tempAmount1) this.tempAmount1 = 0
    if (!this.tempAmount2) this.tempAmount2 = 0
    if (!this.tempAmount3) this.tempAmount3 = 0
    if (!this.projectGuid) this.projectGuid = ''
    if (!this.projectName) this.projectName = ''
    if (!this.constructionUnit) this.constructionUnit = ''
    if (!this.superviseUnit) this.superviseUnit = ''
    if (!this.testUnit) this.testUnit = ''
    if (!this.ownerUnit) this.ownerUnit = ''
    if (!this.superviseCode) this.superviseCode = ''
    if (!this.testCode) this.testCode = ''
    if (!this.staffName) this.staffName = ''
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
    if (!this.meterageType) this.meterageType = ''
    if (!this.a15SerialNo) this.a15SerialNo = 0
    if (!this.a15AutoDate) this.a15AutoDate = 0
    if (!this.name1) this.name1 = ''
    if (!this.name2) this.name2 = ''
    if (!this.name3) this.name3 = ''
    if (!this.name4) this.name4 = ''
    if (!this.name5) this.name5 = ''
    if (!this.name6) this.name6 = ''
    if (!this.name7) this.name7 = ''
    if (!this.name8) this.name8 = ''
    if (!this.name9) this.name9 = ''
    if (!this.name10) this.name10 = ''
    if (!this.name11) this.name11 = ''
    if (!this.name12) this.name12 = ''
    if (!this.name13) this.name13 = ''
    if (!this.name14) this.name14 = ''
    if (!this.name15) this.name15 = ''
    if (!this.tenderFullName) this.tenderFullName = ''
    if (!this.reportCode) this.reportCode = ''
    if (!this.tenderType) this.tenderType = 0
  }

  generatePrimaryKey () {
    this.tenderGuid = utility.getUuid()
    this.resourceId = this.tenderGuid
  }
}
